<template>
  <div class="order-step">
    <template v-for="(stepItem, stepIndex) in stepArray">
      <div :class="[{'is-finish': stepIndex < active - 1, 'is-wait': stepIndex > active - 1}]">
        <div class="step-header">
          <template v-if="stepIndex !== stepArray.length - 1">
            <div class="step__line">
              <div class="step__line-text">
                {{ timeInterval(stepArray[stepIndex].date, stepArray[stepIndex + 1].date) }}
              </div>
              <i class="step__line-inner"
                 style="transition-delay: 0ms; border-width: 1px; width: 100%;"
              ></i>
            </div>
          </template>
          <div class="step__icon">
            <i class="el-icon-success"></i>
          </div>
        </div>
        <div class="step-main">
          <div class="step__title">{{ stepItem.title }}</div>
          <div class="step__description">
            <template v-if="stepItem.description">
              {{ stepItem.description }}
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import DateUtil from "@/resources/js/dateutil";

export default {
  name: "OrderStep",
  props: {
    active: {
      type: Number,
      default: 3
    },
    stepArray: {
      type: Array,
      required: true,
    }
  },
  computed: {
    timeInterval() {
      return function (prev, next) {
        if (prev && next) {
          return DateUtil.formatDuring(next - prev);
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.order-step {
  display: flex;
  align-items: flex-start;
  width: 100%;
  text-align: center;
  padding: 16px 0;

  .is-finish {
    .step__icon {
      color: $main-color;
    }

    .step-main {
      color: $main-color;
    }

    .step__line {
      background-color: $main-color !important;
    }
  }

  .is-wait {
    .step__icon {
      color: #C0C4CC;
    }

    .step-main {
      color: #C0C4CC;
    }

    .step__line {
    }
  }

  > div {
    flex: 1;
    flex-shrink: 0;


    .step-header {
      text-align: center;
      position: relative;

      .step__line {
        position: absolute;
        border-color: inherit;
        background-color: #C0C4CC;
        left: 50%;
        right: -50%;
        height: 2px;
        top: 11px;

        .step__line-text {
          position: absolute;
          left: 20%;
          right: 20%;
          bottom: calc(100% + 5px);
        }

        .step__line-inner {
          display: block;
          border-width: 1px;
          transition: .15s ease-out;
          box-sizing: border-box;
          width: 0;
          height: 0;
        }
      }

      .step__icon {
        position: relative;
        z-index: 1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 24px;
        font-size: 14px;
        box-sizing: border-box;
        background: #FFF;
        transition: .15s ease-out;

        i {
          font-size: 25px;
        }
      }
    }

    .step-main {
      .step__title {
        font-size: 16px;
        //line-height: 38px;
        line-height: 1.5;
        padding: 0 10px;
      }

      .step__description {
        padding: 0 20%;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
      }
    }
  }
}
</style>
